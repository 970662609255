import { Outlet } from 'react-router-dom'
import NavBar from '../../components/NavBar'
import Spinner from '../../components/Spinner'
import useLoading from '../../hooks/use-loading'
import store from '../../store'
import { useRef } from 'react'

const MainLayout: React.FC<Record<string, never>> = () => {
	const [showLoadingOverlay] = useLoading()
	const backgroundImageUrl = useRef(store.getState().userData.backgroundImageUrl)

	return (
		<div
			className="relative w-full min-h-screen bg-main bg-no-repeat bg-cover flex justify-center items-center"
			style={
				!!backgroundImageUrl.current && backgroundImageUrl.current !== 'undefined'
					? { backgroundImage: `url('${backgroundImageUrl.current}')` }
					: {}
			}
		>
			<NavBar />
			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
				{showLoadingOverlay && <Spinner />}
			</div>
			<div className={`${showLoadingOverlay ? 'hidden' : ''}`}>
				<Outlet />
			</div>
		</div>
	)
}

export default MainLayout
