import { createSlice } from '@reduxjs/toolkit'
import {
	ORGANIZATION_KEY,
	TOKEN_KEY,
	USER_ROLE_KEY,
	BACKGROUND_IMAGE_KEY,
	LOGO_IMAGE_KEY,
	PRIMARY_COLOR_KEY,
	SECONDARY_COLOR_KEY,
	CLOSED_ENVELOPE_IMAGE_URL_KEY,
	OPEN_ENVELOPE_IMAGE_URL_KEY,
} from '../../constants/storage'

export const userDataSlice = createSlice({
	name: 'userData',
	initialState: {
		token: localStorage.getItem(TOKEN_KEY) || null,
		organization: localStorage.getItem(ORGANIZATION_KEY) || null,
		role: localStorage.getItem(USER_ROLE_KEY) || null,
		backgroundImageUrl: localStorage.getItem(BACKGROUND_IMAGE_KEY) || undefined,
		logoImageUrl: localStorage.getItem(LOGO_IMAGE_KEY) || undefined,
		primaryColor: localStorage.getItem(PRIMARY_COLOR_KEY) || undefined,
		secondaryColor: localStorage.getItem(SECONDARY_COLOR_KEY) || undefined,
		closedEnvelopeImageUrl: localStorage.getItem(CLOSED_ENVELOPE_IMAGE_URL_KEY) || undefined,
		openEnvelopeImageUrl: localStorage.getItem(OPEN_ENVELOPE_IMAGE_URL_KEY) || undefined,
	},
	reducers: {
		setUserToken: (state, action) => {
			localStorage.setItem(TOKEN_KEY, action.payload)
			state.token = action.payload
		},
		setUserOrganization: (state, action) => {
			localStorage.setItem(ORGANIZATION_KEY, action.payload)
			state.organization = action.payload
		},
		setUserRole: (state, action) => {
			localStorage.setItem(USER_ROLE_KEY, action.payload)
			state.role = action.payload
		},
		setBackgroundImageUrl: (state, action) => {
			localStorage.setItem(BACKGROUND_IMAGE_KEY, action.payload)
			state.backgroundImageUrl = action.payload
		},
		setLogoImageUrl: (state, action) => {
			localStorage.setItem(LOGO_IMAGE_KEY, action.payload)
			state.logoImageUrl = action.payload
		},
		setPrimaryColor: (state, action) => {
			localStorage.setItem(PRIMARY_COLOR_KEY, action.payload)
			state.primaryColor = action.payload
		},
		setSecondaryColor: (state, action) => {
			localStorage.setItem(SECONDARY_COLOR_KEY, action.payload)
			state.secondaryColor = action.payload
		},
		setClosedEnvelopeImageUrl: (state, action) => {
			localStorage.setItem(CLOSED_ENVELOPE_IMAGE_URL_KEY, action.payload)
			state.closedEnvelopeImageUrl = action.payload
		},
		setOpenEnvelopeImageUrl: (state, action) => {
			localStorage.setItem(OPEN_ENVELOPE_IMAGE_URL_KEY, action.payload)
			state.openEnvelopeImageUrl = action.payload
		},
	},
})

export const {
	setUserToken,
	setUserOrganization,
	setUserRole,
	setBackgroundImageUrl,
	setLogoImageUrl,
	setPrimaryColor,
	setSecondaryColor,
	setClosedEnvelopeImageUrl,
	setOpenEnvelopeImageUrl,
} = userDataSlice.actions

export default userDataSlice.reducer
