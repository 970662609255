import { ComponentType, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import digitalbumApiClient from '../apis/digitalbum-api-client'
import { TOKEN_KEY } from '../constants/storage'

export type WrappedComponentType<P extends object> = ComponentType<P>
export type WrappedComponentProps<P extends object> = React.ComponentProps<WrappedComponentType<P>>

const withJwtTokenVerification = <P extends object>(WrappedComponent: ComponentType<P>) => {
	const WithJwtTokenVerification: React.FC<WrappedComponentProps<P>> = (props) => {
		const [renderWrappedComponent, setRenderWrappedComponent] = useState<boolean>(false)
		const navigate = useNavigate()

		const evaluateToken = useCallback(async () => {
			const token = localStorage.getItem(TOKEN_KEY) || null

			if (!token) {
				navigate('/login')
			} else {
				try {
					const {
						data: { expired },
					} = await digitalbumApiClient.isTokenExpired(token)

					if (expired) {
						navigate('/login')
					} else {
						setRenderWrappedComponent(true)
					}
				} catch (e) {
					navigate('/login')
				}
			}
		}, [navigate])

		useEffect(() => {
			evaluateToken()
		}, [evaluateToken])

		return <div>{renderWrappedComponent ? <WrappedComponent {...props} /> : <></>}</div>
	}

	return WithJwtTokenVerification
}

export default withJwtTokenVerification
