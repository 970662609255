import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import pt from './locales/pt/translation.json'

export type AvailableLanguage = 'en' | 'es' | 'pt'

export const AVAILABLE_LANGUAGES: AvailableLanguage[] = ['en', 'es', 'pt']

const resources = {
	en: {
		translation: en,
	},
	es: {
		translation: es,
	},
	pt: {
		translation: pt,
	},
}

i18n.use(initReactI18next).init({
	compatibilityJSON: 'v3',
	resources,
	lng: 'es',
	interpolation: {
		escapeValue: false,
	},
	react: { useSuspense: false },
})

export default i18n
