import Spinner from '../../components/Spinner'
import { Outlet } from 'react-router-dom'
import AdminNavBar from '../../components/AdminNavBar'
import useLoading from '../../hooks/use-loading'
import withAdminTokenVerification from '../../hooks/with-admin-token-verification'

const AdminLayout: React.FC<Record<string, never>> = () => {
	const [showLoadingOverlay] = useLoading()

	return (
		<div className="relative w-full min-h-screen flex justify-between items-center">
			<div className="w-[300px] bg-[#3d3d6b] border-r-2 border-[#31314b] min-h-screen p-5 fixed top-0 bottom-0">
				<AdminNavBar />
			</div>
			<div className="w-[calc(100%-300px)] min-h-screen h-full absolute left-[300px]">
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
					{showLoadingOverlay && <Spinner />}
				</div>
				<div
					className={
						showLoadingOverlay ? 'hidden' : 'bg-main bg-no-repeat bg-cover bg-center min-h-screen'
					}
				>
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default withAdminTokenVerification(AdminLayout)
