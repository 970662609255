import { useLocation, useNavigate } from 'react-router-dom'
import {
	getCurrentRouteWithoutLanguageFromPathname,
	getUrlWithCurrentLanguage,
} from '../../router/utils'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import Dropdown from '../Dropdown'
import { FLAGS_DICT } from '../../utils/lng'
import i18n, { AvailableLanguage } from '../../i18n'
import { LOCALE_KEY } from '../../constants/storage'
import store from '../../store'

interface MobileMenuProps {
	isOpen: boolean
	onClose: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { pathname } = useLocation()
	const currentRouteWithoutLanguage = getCurrentRouteWithoutLanguageFromPathname(pathname)

	const [selectedRoute, setSelectedRoute] = useState(currentRouteWithoutLanguage)

	const primaryColor = useRef(store.getState().userData.primaryColor)
	const secondaryColor = useRef(store.getState().userData.secondaryColor)

	const selectLanguage = (lang: AvailableLanguage) => {
		localStorage.setItem(LOCALE_KEY, lang)
		navigate(`/${lang}/${currentRouteWithoutLanguage}`)
	}

	return (
		<div
			className={`fixed left-0 top-0 bottom-0 z-20 w-[300px] max-[300px]:w-full transition-all duration-500 bg-[#3d3d6b] border-r-2 border-[#31314b] ${!isOpen ? '-translate-x-[300px]' : ''}`}
			style={
				!!primaryColor.current
					? { backgroundColor: primaryColor.current, borderColor: primaryColor.current }
					: {}
			}
		>
			<div className="flex flex-col gap-y-4 relative p-5 pt-10">
				<a
					href={getUrlWithCurrentLanguage('your-envelopes')}
					className={`text-xl font-bold mr-5 ${selectedRoute === '' || selectedRoute === 'your-envelopes' ? 'text-yellow-300' : ''}`}
					onClick={() => setSelectedRoute('your-envelopes')}
					style={
						!!secondaryColor.current
							? {
									color:
										selectedRoute === '' || selectedRoute === 'your-envelopes'
											? secondaryColor.current
											: '#fff',
								}
							: {}
					}
				>
					{t('navbar.your_envelopes')}
				</a>
				<a
					href={getUrlWithCurrentLanguage('album')}
					className={`text-xl font-bold mr-5 ${selectedRoute === 'album' ? 'text-yellow-300' : ''}`}
					onClick={() => setSelectedRoute('album')}
					style={
						!!secondaryColor.current
							? { color: selectedRoute === 'album' ? secondaryColor.current : '#fff' }
							: {}
					}
				>
					{t('navbar.album')}
				</a>
				<a
					href={getUrlWithCurrentLanguage('exchange')}
					className={`text-xl font-bold mr-5 ${selectedRoute === 'exchange' ? 'text-yellow-300' : ''}`}
					onClick={() => setSelectedRoute('exchange')}
					style={
						!!secondaryColor.current
							? { color: selectedRoute === 'exchange' ? secondaryColor.current : '#fff' }
							: {}
					}
				>
					{t('navbar.exchange')}
				</a>
				<a
					href={getUrlWithCurrentLanguage('ranking-and-prizes')}
					className={`text-xl font-bold ${selectedRoute === 'ranking-and-prizes' ? 'text-yellow-300' : ''}`}
					onClick={() => setSelectedRoute('ranking-and-prizes')}
					style={
						!!secondaryColor.current
							? { color: selectedRoute === 'ranking-and-prizes' ? secondaryColor.current : '#fff' }
							: {}
					}
				>
					{t('navbar.ranking_and_prizes')}
				</a>
				<Dropdown
					title={'Languages'}
					options={[
						{
							displayText: FLAGS_DICT.es,
							action: () => selectLanguage('es'),
						},
						{
							displayText: FLAGS_DICT.pt,
							action: () => selectLanguage('pt'),
						},
						{
							displayText: FLAGS_DICT.en,
							action: () => selectLanguage('en'),
						},
					]}
					defaultOptionDisplayText={FLAGS_DICT[i18n.language as AvailableLanguage]}
					showDefaultOptionDisplayTextAsTitle
				/>
				<div className="absolute top-5 right-4 cursor-pointer text-zinc-200">
					<FontAwesomeIcon icon={faClose} onClick={onClose} className="w-5 h-5" />
				</div>
			</div>
		</div>
	)
}

export default MobileMenu
