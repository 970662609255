import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import digitalbumApiClient from '../../apis/digitalbum-api-client'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { LOCALE_KEY } from '../../constants/storage'
import { useDispatch } from 'react-redux'
import {
	setUserOrganization,
	setUserToken,
	setUserRole,
	setBackgroundImageUrl,
	setLogoImageUrl,
	setPrimaryColor,
	setSecondaryColor,
	setClosedEnvelopeImageUrl,
	setOpenEnvelopeImageUrl,
} from '../../store/slices/userDataSlice'

const Login: React.FC<Record<string, never>> = () => {
	const orgSlug = useLoaderData()

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [orgLogoUrl, setOrgLogoUrl] = useState('')
	const [orgBackgroundImageUrl, setOrgBackgroundImageUrl] = useState('')

	const login = async () => {
		try {
			const authResponse = await digitalbumApiClient.authenticate({ email, password })

			if (!authResponse.success) {
				setErrorMessage(authResponse.message)
			} else {
				dispatch(setUserToken(authResponse.data.token))
				dispatch(setUserOrganization(authResponse.data.userData.organization.name))

				if (authResponse.data.userData.team.games.length) {
					dispatch(
						setBackgroundImageUrl(
							authResponse.data.userData.team.games[0].backgroundImage ?? undefined,
						),
					)
					dispatch(setLogoImageUrl(authResponse.data.userData.team.games[0].logoImage ?? undefined))
					dispatch(
						setPrimaryColor(authResponse.data.userData.team.games[0].primaryColor ?? undefined),
					)
					dispatch(
						setSecondaryColor(authResponse.data.userData.team.games[0].secondaryColor ?? undefined),
					)
					dispatch(
						setClosedEnvelopeImageUrl(authResponse.data.userData.team.games[0].closedEnvelopeImage),
					)
					dispatch(
						setOpenEnvelopeImageUrl(authResponse.data.userData.team.games[0].openEnvelopeImage),
					)
				}

				if (
					authResponse.data.userData.role.name === 'admin' ||
					authResponse.data.userData.role.name === 'org_admin'
				) {
					dispatch(setUserRole(authResponse.data.userData.role.name))
					navigate('/admin')
				} else {
					navigate(`/${localStorage.getItem(LOCALE_KEY) || 'es'}`)
				}
			}
		} catch (e: any) {
			setErrorMessage(e.message)
		}
	}

	const handleOnEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (errorMessage.length > 0) {
			setErrorMessage('')
		}

		setEmail(event.target.value)
	}

	const handleOnPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (errorMessage.length > 0) {
			setErrorMessage('')
		}

		setPassword(event.target.value)
	}

	const fetchOrgDataIfAny = useCallback(async () => {
		if (orgSlug) {
			const response = await digitalbumApiClient.getOrganizationBySlug(orgSlug as string)

			setOrgLogoUrl(response.data.logoUrl)
			setOrgBackgroundImageUrl(response.data.backgroundImageUrl)
		}
	}, [orgSlug])

	useEffect(() => {
		fetchOrgDataIfAny()
	}, [fetchOrgDataIfAny])

	return (
		<div
			className={`h-screen w-full bg-zinc-100 text-zinc-900 bg-no-repeat bg-cover ${!orgBackgroundImageUrl ? 'bg-main' : ''}`}
			style={orgBackgroundImageUrl ? { backgroundImage: `url('${orgBackgroundImageUrl}')` } : {}}
		>
			<div className="w-full h-full flex justify-center items-center">
				<div className="px-5">
					<div className="px-10 py-16 bg-white shadow-md rounded-md flex flex-col justify-center items-center">
						{orgLogoUrl && <img src={orgLogoUrl} alt="" className="mb-5" />}
						<div className="text-center text-3xl font-semibold mb-8">Login</div>
						<div className="max-w-[350px] mx-auto mb-5">
							<input
								className="border-2 border-zinc-300 rounded-md text-base w-full px-5 py-3 mb-4"
								type="text"
								value={email}
								onChange={handleOnEmailChange}
							/>
							<input
								className="border-2 border-zinc-300 rounded-md text-base w-full px-5 py-3"
								type="password"
								value={password}
								onChange={handleOnPasswordChange}
							/>
						</div>
						<div className="min-h-5 text-red-500 max-w-[350px] mb-5">{errorMessage}</div>
						<button
							className="border-2 border-zinc-300 hover:bg-zinc-100 transition-all px-5 py-3 rounded-md font-semibold"
							onClick={login}
						>
							Ingresar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
