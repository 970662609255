import { createSlice } from '@reduxjs/toolkit'

export const appLayoutSlice = createSlice({
	name: 'appLayout',
	initialState: {
		loading: false,
		closedEnvelopeImageUrl: null,
		openEnvelopeImageUrl: null,
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload
		},
	},
})

export const { setLoading } = appLayoutSlice.actions

export default appLayoutSlice.reducer
