import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { TOKEN_KEY } from '../../constants/storage'
import { useEffect, useState } from 'react'
import digitalbumApiClient from '../../apis/digitalbum-api-client'

const AdminNavBar: React.FC<Record<string, never>> = () => {
	const navigate = useNavigate()

	const [isUserSuperAdminStatus, setIsUserSuperAdminStatus] = useState(false)

	const handleLogout = () => {
		localStorage.removeItem(TOKEN_KEY)
		navigate('/login')
	}

	const fetchUserSuperAdminStatus = async () => {
		const token = localStorage.getItem(TOKEN_KEY)!

		const {
			data: { isUserSuperAdmin },
		} = await digitalbumApiClient.isAdminToken(token)

		setIsUserSuperAdminStatus(isUserSuperAdmin)
	}

	useEffect(() => {
		fetchUserSuperAdminStatus()
	}, [])

	return (
		<div className="flex flex-col justify-between">
			<div>
				<div className="text-white font-semibold mb-10">Admin Panel</div>
				<div className="flex flex-col text-yellow-300 font-bold text-xl">
					<a href="/admin/new-game" className="mb-5">
						Crear juego nuevo
					</a>
					<a href="/admin/current-games" className="mb-5">
						Juegos activos
					</a>
					{isUserSuperAdminStatus && [
						<a href="/admin/new-organization" className="mb-5">
							Crear nueva organización
						</a>,
						<a href="/admin/organizations" className="mb-5">
							Organizaciones
						</a>,
					]}
				</div>
			</div>
			<div className="absolute bottom-5 left-5 text-white">
				<a href="#logout" onClick={handleLogout} className="font-semibold cursor-pointer">
					<span className="mr-2">
						<FontAwesomeIcon icon={faSignOut} />
					</span>
					Logout
				</a>
			</div>
		</div>
	)
}

export default AdminNavBar
