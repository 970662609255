import { Navigate, createBrowserRouter } from 'react-router-dom'
import { redirect } from 'react-router-dom'
import i18n, { AVAILABLE_LANGUAGES } from '../i18n'
import { slugToPascalCase } from '../utils/str'
import MainLayout from '../Layouts/MainLayout'
import Login from '../pages/Login'
import withJwtTokenVerification from '../hooks/with-jwt-token-verification'
import NotFound from '../pages/NotFound'
import AdminLayout from '../Layouts/AdminLayout'
import { LOCALE_KEY } from '../constants/storage'

const ROUTES = ['your-envelopes', 'album', 'exchange', 'ranking-and-prizes']

const getComponentLazyLoader = (view: string) => async () => {
	let Component = null

	try {
		Component = (await import(`../pages/${view}`)).default
	} catch (e) {
		Component = (await import('../pages/NotFound')).default
	}

	return { Component: withJwtTokenVerification(Component) }
}

const router = createBrowserRouter([
	{
		path: '/',
		loader: async () => redirect(`${localStorage.getItem(LOCALE_KEY) || 'es'}`),
	},
	{
		path: '/login/:organization?',
		loader: async ({ params }) => params.organization || null,
		element: <Login />,
	},
	{
		path: '/:language',
		loader: async ({ request, params }) => {
			if ((AVAILABLE_LANGUAGES as (string | undefined)[]).indexOf(params.language) === -1) {
				return redirect('/not-found')
			}

			if (i18n.language !== params.language) {
				i18n.changeLanguage(params.language)
			}

			return { request, params }
		},
		element: <MainLayout />,
		children: [
			...ROUTES.map((slug) => ({
				path: slug,
				lazy: getComponentLazyLoader(slugToPascalCase(slug)),
			})),
			{
				index: true,
				element: <Navigate to={'your-envelopes'} />,
			},
		],
	},
	{
		path: '/not-found',
		element: <NotFound />,
	},
	{
		path: '/admin',
		element: <AdminLayout />,
		children: [
			{
				path: 'new-game',
				lazy: getComponentLazyLoader('NewGame'),
			},
			{
				path: 'current-games',
				lazy: getComponentLazyLoader('CurrentGames'),
			},
			{
				path: 'current-game',
				lazy: getComponentLazyLoader('CurrentGame'),
			},
			{
				path: 'new-organization',
				lazy: getComponentLazyLoader('NewOrganization'),
			},
			{
				path: 'organizations',
				lazy: getComponentLazyLoader('Organizations'),
			},
			{
				path: 'organization',
				lazy: getComponentLazyLoader('Organization'),
			},
			{
				index: true,
				lazy: getComponentLazyLoader('Admin'),
			},
		],
	},
])

export default router
