import store from '../store'

interface AuthenticateRequest {
	email: string
	password: string
}

// TODO: type methods returned promises
class DigitalbumApiClient {
	private static BASE_URL = process.env.REACT_APP_DIGITALBUM_BACKEND_BASE_URL

	public async authenticate({ email, password }: AuthenticateRequest): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/auth/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					password,
				}),
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async isTokenExpired(token: string): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/auth/token/check-expired`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token,
				}),
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async isAdminToken(token: string): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/auth/token/is-admin`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token,
				}),
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getLatestEnvelope(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/envelope/latest`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async openLatestEnvelope(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/envelope/latest/open`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getAlbum(page: number): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/album?page=${page}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getRepeatedStickers(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/sticker/repeated`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async pasteStickers(stickerNames: string[]): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/sticker/paste`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
				body: JSON.stringify({
					stickerNames,
				}),
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async exchangeStickers(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/exchange`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getCurrentRunExchange(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/exchange/current-run`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getExchangedStickers(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/sticker/exchanged`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getRanking(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/ranking`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async resetGame(gameId: number): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/reset-game/${gameId}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async makeGameInactive(gameId: number): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/disable-game/${gameId}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async deliverNewEnvelopeForGame(gameId: number): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/new-envelope/${gameId}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getGamesList(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/game`, {
				headers: {
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async createGame(gameParameters: any): Promise<any> {
		const fd = new FormData()

		for (const [gameParameterKey, gameParameterValue] of Object.entries(gameParameters)) {
			fd.append(gameParameterKey, gameParameterValue as string | Blob)
		}

		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/game`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
				body: fd,
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async editGame(gameId: number, gameParameters: any): Promise<any> {
		const fd = new FormData()

		for (const [gameParameterKey, gameParameterValue] of Object.entries(gameParameters)) {
			fd.append(gameParameterKey, gameParameterValue as string | Blob)
		}

		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/game/${gameId}`, {
				method: 'PUT',
				headers: {
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
				body: fd,
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getOrganizationsList(): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/organization`, {
				headers: {
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async getOrganizationBySlug(slug: string): Promise<any> {
		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/organization/alt?slug=${slug}`)

			return response.json()
		} catch (e) {
			throw e
		}
	}

	public async createOrganization(organizationParameters: any): Promise<any> {
		const fd = new FormData()

		for (const [orgParameterKey, orgParameterValue] of Object.entries(organizationParameters)) {
			fd.append(orgParameterKey, orgParameterValue as string | Blob)
		}

		try {
			const response = await fetch(`${DigitalbumApiClient.BASE_URL}/organization`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${store.getState().userData.token}`,
				},
				body: fd,
			})

			return response.json()
		} catch (e) {
			throw e
		}
	}
}

const digitalbumApiClient = new DigitalbumApiClient()

export default digitalbumApiClient
