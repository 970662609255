import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../store'
import { setLoading } from '../store/slices/appLayoutSlice'

const useLoading = () => {
	const dispatch = useDispatch()

	const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)

	const loading = useSelector((state: IRootState) => state.appLayout.loading)

	useEffect(() => {
		if (!loading) {
			const timeoutId = setTimeout(() => setShowLoadingOverlay(loading), 500)

			return () => clearTimeout(timeoutId)
		}

		setShowLoadingOverlay(loading)
	}, [loading])

	return [showLoadingOverlay, (loading: boolean) => dispatch(setLoading(loading))]
}

export default useLoading
