import { configureStore } from '@reduxjs/toolkit'
import userDataSlice from './slices/userDataSlice'
import appLayoutSlice from './slices/appLayoutSlice'

const store = configureStore({
	reducer: {
		userData: userDataSlice,
		appLayout: appLayoutSlice,
	},
})

export type IRootState = ReturnType<typeof store.getState>

export default store
