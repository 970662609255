import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import useOutsideClick from '../../hooks/use-outside-click'

interface DropdownProps {
	title: string
	options: {
		displayText: string
		link?: string
		action?: () => void
	}[]
	defaultOptionDisplayText: string
	showDefaultOptionDisplayTextAsTitle?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
	title,
	options,
	defaultOptionDisplayText,
	showDefaultOptionDisplayTextAsTitle = false,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const outsideClickRef = useOutsideClick<HTMLButtonElement>(() => setIsOpen(false))

	return (
		<div className="relative inline-block text-left">
			<div>
				<button
					type="button"
					className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					id="menu-button"
					aria-expanded="true"
					aria-haspopup="true"
					onClick={() => setIsOpen(!isOpen)}
					ref={outsideClickRef}
				>
					<span className="px-2 text-lg">
						{showDefaultOptionDisplayTextAsTitle ? defaultOptionDisplayText : title}
					</span>
					<FontAwesomeIcon icon={faChevronDown} className="-mr-1 h-3 w-3 text-gray-400" />
				</button>
			</div>
			<div
				className={`${!isOpen ? 'hidden' : ''} absolute right-0 z-10 mt-2 min-w-[4.5rem] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
				role="menu"
				aria-orientation="vertical"
				aria-labelledby="menu-button"
				tabIndex={-1}
			>
				<div className="py-1" role="none">
					{options.map((option, idx) => (
						<a
							href={option.link ?? '#'}
							className="text-gray-700 block px-4 py-2 text-lg ml-1"
							role="menuitem"
							tabIndex={-1}
							id="menu-item-0"
							onClick={option.action}
							key={idx}
						>
							{option.displayText}
						</a>
					))}
				</div>
			</div>
		</div>
	)
}

export default Dropdown
