const PREFIX = 'digitalbum__'

export const TOKEN_KEY = `${PREFIX}token`
export const LOCALE_KEY = `${PREFIX}locale`
export const ORGANIZATION_KEY = `${PREFIX}organization`
export const USER_ROLE_KEY = `${PREFIX}user_role`
export const BACKGROUND_IMAGE_KEY = `${PREFIX}background_image`
export const LOGO_IMAGE_KEY = `${PREFIX}logo_image`
export const PRIMARY_COLOR_KEY = `${PREFIX}primary_color`
export const SECONDARY_COLOR_KEY = `${PREFIX}secondary_color`
export const CLOSED_ENVELOPE_IMAGE_URL_KEY = `${PREFIX}closed_envelope_image_url_key`
export const OPEN_ENVELOPE_IMAGE_URL_KEY = `${PREFIX}open_envelope_image_url_key`
