import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from '../Dropdown'
import { useTranslation } from 'react-i18next'
import { AvailableLanguage } from '../../i18n'
import {
	getCurrentRouteWithoutLanguageFromPathname,
	getUrlWithCurrentLanguage,
} from '../../router/utils'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOut } from '@fortawesome/free-solid-svg-icons'
import MobileMenu from '../MobileMenu'
import { LOCALE_KEY, TOKEN_KEY } from '../../constants/storage'
import { FLAGS_DICT } from '../../utils/lng'
import store from '../../store'
import { hexToRgb } from '../../utils/colors'

const NavBar: React.FC<Record<string, never>> = () => {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const currentRouteWithoutLanguage = getCurrentRouteWithoutLanguageFromPathname(pathname)

	const [selectedRoute, setSelectedRoute] = useState(currentRouteWithoutLanguage)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

	const primaryColor = useRef(store.getState().userData.primaryColor)
	const secondaryColor = useRef(store.getState().userData.secondaryColor)
	const logoImageUrl = useRef(store.getState().userData.logoImageUrl)

	useEffect(() => {
		setSelectedRoute(getCurrentRouteWithoutLanguageFromPathname(pathname))
	}, [pathname])

	const selectLanguage = (lang: AvailableLanguage) => {
		localStorage.setItem(LOCALE_KEY, lang)
		navigate(`/${lang}/${currentRouteWithoutLanguage}`)
	}

	const logout = () => {
		localStorage.setItem(TOKEN_KEY, '')
		navigate('/')
	}

	return (
		<div
			className="absolute top-0 left-0 w-full bg-zinc-900/70 text-white"
			style={
				!!secondaryColor.current
					? { backgroundColor: `rgb(${hexToRgb(secondaryColor.current)}, 0.7)` }
					: {}
			}
		>
			<div className="max-w-[1200px] mx-auto px-10 max-[768px]:px-5 py-6">
				<header className="flex justify-between">
					<div className="flex items-center">
						<div className="hidden max-[1000px]:block mr-10 max-[768px]:mr-5">
							<FontAwesomeIcon
								icon={faBars}
								className="text-2xl max-[768px]:text-xl cursor-pointer"
								onClick={() => setIsMobileMenuOpen(true)}
							/>
						</div>
						{logoImageUrl.current && logoImageUrl.current !== 'undefined' && (
							<div className="max-w-[180px]">
								<img src={logoImageUrl.current} alt="logo" className="max-h-16" />
							</div>
						)}
						{!logoImageUrl.current ||
							(logoImageUrl.current === 'undefined' && (
								<div
									className="text-3xl max-[768px]:text-2xl font-semibold cursor-pointer"
									onClick={() => navigate(`/${i18n.language}`)}
								>
									Digitalbum
								</div>
							))}
					</div>
					<div className="flex justify-between items-center max-[1000px]:hidden">
						<a
							href={getUrlWithCurrentLanguage('your-envelopes')}
							className={`text-xl font-bold mr-5 ${selectedRoute === '' || selectedRoute === 'your-envelopes' ? 'text-yellow-300' : ''}`}
							onClick={() => setSelectedRoute('your-envelopes')}
							style={
								!!primaryColor.current
									? {
											color:
												selectedRoute === '' || selectedRoute === 'your-envelopes'
													? primaryColor.current
													: '#fff',
										}
									: {}
							}
						>
							{t('navbar.your_envelopes')}
						</a>
						<a
							href={getUrlWithCurrentLanguage('album')}
							className={`text-xl font-bold mr-5 ${selectedRoute === 'album' ? 'text-yellow-300' : ''}`}
							onClick={() => setSelectedRoute('album')}
							style={
								!!primaryColor.current
									? { color: selectedRoute === 'album' ? primaryColor.current : '#fff' }
									: {}
							}
						>
							{t('navbar.album')}
						</a>
						<a
							href={getUrlWithCurrentLanguage('exchange')}
							className={`text-xl font-bold mr-5 ${selectedRoute === 'exchange' ? 'text-yellow-300' : ''}`}
							onClick={() => setSelectedRoute('exchange')}
							style={
								!!primaryColor.current
									? { color: selectedRoute === 'exchange' ? primaryColor.current : '#fff' }
									: {}
							}
						>
							{t('navbar.exchange')}
						</a>
						<a
							href={getUrlWithCurrentLanguage('ranking-and-prizes')}
							className={`text-xl font-bold ${selectedRoute === 'ranking-and-prizes' ? 'text-yellow-300' : ''}`}
							onClick={() => setSelectedRoute('ranking-and-prizes')}
							style={
								!!primaryColor.current
									? {
											color: selectedRoute === 'ranking-and-prizes' ? primaryColor.current : '#fff',
										}
									: {}
							}
						>
							{t('navbar.ranking_and_prizes')}
						</a>
					</div>
					<div className="max-[1000px]:hidden flex items-center justify-center">
						<Dropdown
							title={'Languages'}
							options={[
								{
									displayText: FLAGS_DICT.es,
									action: () => selectLanguage('es'),
								},
								{
									displayText: FLAGS_DICT.pt,
									action: () => selectLanguage('pt'),
								},
								{
									displayText: FLAGS_DICT.en,
									action: () => selectLanguage('en'),
								},
							]}
							defaultOptionDisplayText={FLAGS_DICT[i18n.language as AvailableLanguage]}
							showDefaultOptionDisplayTextAsTitle
						/>
					</div>
					<button className="flex items-center justify-center font-semibold" onClick={logout}>
						<span className="max-[350px]:hidden">{t('navbar.logout')}</span>
						<FontAwesomeIcon icon={faSignOut} className="ml-2" />
					</button>
				</header>
			</div>
			<MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
		</div>
	)
}

export default NavBar
